.modalpanel_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.modalpanel_wrapper form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}

.modalpanel_wrapper .modal_submit_button {
    margin: 1rem;
    display: flex;
    width: 120px;
    outline: none;
    border: none;
    font-family: var(--font-family);
    padding: 0.5rem 1rem;
    transition: all 0.4s ease;
    cursor: pointer;
}

.modalpanel_header {
    align-items: center;
    display: flex;
    font-family: var(--font-family);
    font-size: 32px;
    font-weight: 200;
    justify-content: center;
    padding: 2rem;
    width: 100%;
}


.form_input-create h4 {
    font-family: var(--font-family);
    margin: 2rem 0;
    font-size: 18px;
    font-weight: 300;
}



/* Options */

.NewEditOptions_wrapper {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}


.CreateModalForm {
    display: flex;
    height: 50vh;
    width: 50%;
    margin: auto;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    position: fixed;
    top: 25%;
    left: 25%;
    border-radius: 1rem;
}

.EditModalForm {
    display: flex;
    height: 50vh;
    width: 50%;
    margin: auto;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    position: fixed;
    top: 25%;
    left: 25%;
    border-radius: 1rem;
}

.DeviceOption {
    display: flex;
    justify-content: center;
    margin: auto 0.5rem !important;
    width: auto !important;
}

.form_input-create .file-drop-area {
    border: 2px dashed var(--color-400);
    border-radius: 4px;
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    transition: all 0.2s ease;
}

.form_input-create .file-drop-area:hover {
    background: var(--bc-background-color-3);
    border: 2px solid var(--color-400);
    transition: all 0.2s ease;
}

.form_input-create .file-drop-area:hover .upload_icon {
    color: var(--color-200);
    transition: all 0.2s ease;
}

.form_input-create .file-drop-area input[type="file"] {
    cursor: pointer;
    height: 100%;
    opacity: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.form_input-create .file-message {
    pointer-events: none;
    position: absolute;
}

.upload_icon {
    font-size: 48px;
    color: var(--color-200);
    scale: 1;
    transition: all 0.2s ease;
}

.modalPill {
    position: fixed;
    width: 25%;
    flex-direction: row;
    flex-direction: column;
}

.modalPill .nav-item {
    width: 25%;
    text-align: center;
}

.modalpanel_wrapper .row .col-3 .nav-item {
    width: 100% !important;
}

@media screen and (max-width: 1200px) {
    .modalPill {
        position: relative;
        width: 100% !important;
        flex-direction: column;
    }

    .modalpanel_wrapper .row {
        flex-direction: column !important;
    }

    .modalpanel_wrapper .row .col-3 {
        width: 100% !important;
    }
}