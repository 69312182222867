.nav_wrapper {
    display: flex;
    position: fixed;
    z-index: 9999;
    flex-direction: column;
    width: 100%;
    height: 100vh;

    background: rgba(0, 0, 0, 1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(12.3px);
    -webkit-backdrop-filter: blur(12.3px);

}

.nav_wrapper p {
    color: var(--light-2);
    margin: 1rem auto;
    font-size: 18px;
    font-family: var(--font-family);
}

.nav_wrapper h3 {
    color: var(--light-2);
    font-size: 52px;
    text-transform: uppercase;
    font-family: var(--font-family);
    margin: auto;
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 1rem;
}

.Navbar-toggle {
    background: none;
    outline: none;
    border: none;
    height: 60px;
    z-index: 999;

    display: flex;
    align-items: center;
    position: fixed;
    flex: 5;
}

.hamburger-react {
    z-index: 999;
    display: flex;
    border-radius: 10rem;
    margin: 1rem;
}

.Nav-link {
    color: var(--light-3) !important;
    font-size: 28px !important;
    font-weight: 200 !important;
    list-style: none !important;
    margin: 0 0.5rem !important;
    padding: 1rem !important;
    text-decoration: none !important;
    text-transform: uppercase !important;
    display: inline-block;
    
    transition: all .4s ease-out !important;
}

.Nav-link::after {
    color: white !important;
    transition: all .2s ease-out !important;

    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: var(--primary);
}
.Nav-link:hover::after {
    width: 100%;
}

.Navbar-links {
    list-style: none;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: auto;
    margin: auto;
    width: 100%;
    flex-wrap: wrap;
    font-family: var(--font-family);
}

.transparent {
    background: none;
}