.modalpanel_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.EditCreateButton_wrapper {
    display: flex;
    flex-direction: row;
}

.modalpanel_wrapper h2 {
    align-items: center;
    font-family: var(--font-family);
    display: flex;
    font-size: 32px;
    font-weight: 200;
    justify-content: left;
    padding: 2rem;
    width: 100%;
}


.modal-list ul li {
    display: flex;
    flex-direction: row;
    list-style: none;
    align-items: center;
}

.modalListButtonWrapper {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin: 0 1.5rem;
}