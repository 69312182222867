    .sidenavToggle {
        left: 0%;
        width: 100%;
    }

@media screen and (max-height: 900px) {
    .sidenav {
        overflow: auto !important;
    }
    .logo-wrapper {
        display: none !important;
        height: 0 !important;
        position: fixed !important;
    }

    .sidenav-menu {
        padding-block: 2rem !important;
    }

    .sidenavToggle {
        justify-content: end !important;
        left: 80% !important;
        width: 25% !important;
    }
}

@media screen and (max-width: 420px) {
    .sidenavToggle {
        justify-content: end !important;
        left: 80% !important;
        width: 25% !important;
    }
}