.file-upload-wrapper {
  width: 100%;
}

.file-upload {
  position: relative;
  width: 100%;
  // box-shadow: $box-shadow-1;
  height: 200px;
}

.file-upload:not(.has-error):hover .file-upload-mask {
  opacity: 0.06;
}

.file-upload-input.has-multiple ~ .file-upload-previews .file-upload-preview:hover .file-upload-preview-details {
  opacity: 1;
}

.file-upload:hover .file-upload-input:not(.has-multiple) ~ .file-upload-previews .file-upload-preview-details {
  opacity: 1;
}

.file-upload.has-error:hover .file-upload-errors {
  opacity: 1;
}

.file-upload.has-error .file-upload-errors {
  display: flex;
}

.file-upload.disabled {
  pointer-events: none;

  .file-upload-message {
    opacity: 0.5;
    text-decoration: line-through;
  }
}

.file-upload-message {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.file-upload-cloud-icon {
  font-size: 2.2rem;
}

.file-upload-main-error {
  font-weight: bold;
  // color: $danger;
  margin: 0;
}

.file-upload-input {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.file-upload-input.has-multiple {
  z-index: unset;
}

.file-upload-mask {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  background-color: #000;
  transition: 0.2s all;
}

.file-upload-errors {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  opacity: 0;
  width: 100%;
  height: 100%;
  background: rgba(243, 65, 65, 0.8);
  justify-content: center;
  flex-direction: column;

  transition: 0.2s all;
}

.file-upload-error {
  color: #fff;
}

.file-upload-previews {
  display: flex;
  width: 100%;
  height: 100%;
}

.file-upload-preview {
  background-color: #fff;
  cursor: pointer;
  position: relative;
  flex-grow: 1;
  flex-basis: 0;
}

.file-upload-render {
  width: 100%;
  height: 100%;
}

.file-upload-file-icon {
  font-size: 80px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  color: #777;
}

.file-upload-extension {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin-top: 10px;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: -0.03em;
  font-size: 1rem;
  color: #fff;
  width: 42px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.file-upload-preview-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.file-upload-preview-details {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: rgba(255, 255, 255, 0.959);
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  flex-direction: column;
  display: flex;
  opacity: 0;
  transition: 0.2s all;
}
.file-upload-remove-file-btn {
  position: absolute;
  right: 15px;
  top: 10px;
  z-index: 3;
}

.file-upload-details-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-uplod-preview-details-inner {
  text-align: center;
}
