/* ====================
    Modal Content 
 ==================== */

/* START MODAL BUTTONS */

.toggle_button {
  max-width: 200px;
  border: none;
  outline: none;
  margin: 0;
  margin: 0 2rem 1rem;
  padding: 1rem 2rem;
  border-radius: 2rem;
  font-family: var(--font-family);
  color: white;
  background-color: crimson;
  font-size: 18px;

  -webkit-animation: blink-2 0.9s both;
  animation: blink-2 0.9s both;
}

@-webkit-keyframes blink-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

/* END MODAL BUTTONS */

.modal-wrapper {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0%;
  left: 0%;
  margin: auto;
  padding: 0;
  z-index: 999;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  background: rgba(36, 36, 36, 0.871);
}

.modal-header h2 {
  font-family: var(--font-family);
  color: #555;
  font-size: 22px;
  line-height: 28px;
  margin: 1rem 0;
}

.viken_modal-content {
  display: flex;
  flex-direction: column;
  height: 100% !important;
  width: 80%;

  background: #fff;
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: 10px 20px 30px 10px rgba(0, 0, 0, 0.25);

  animation-name: fade-in-top;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;

  /* shorthand
		animation: fade-in-top 1s ease 0s 1 normal none;*/
}


@keyframes fade-in-top {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

  .viken__img-container {
    display: flex;
    /* max-height: 300px;
    min-height: 300px;
    max-width: 450px; */
    margin: 0 auto;
    justify-content: center;
    align-items: center;
  }

.modal_text-wrapper {
    position: relative;
    width: 90%;
    margin: auto;
    max-width: 500px;
    height: 250px;
}

.tags {
    display: flex;
    width: 100%;
    padding: 0.6rem 0rem;
    flex-wrap: wrap;
    align-items: center;
}

.tags h5 {
    font-weight: 300;
    font-size: 14px;
    padding: 0rem 0.4rem;
    font-family: var(--font-family);
    color: var(--color-400);
    cursor:default;
    width: auto;
}

.tags p {
    font-weight: 300;
    font-size: 16px;
    font-family: var(--font-family);
    cursor:default
}


.viken__docs-container_documents-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.viken__docs-container_documents-list_box {
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
  display: flex;
  flex-direction: column;
  margin: 1rem 2rem;
  max-height: 600px;
  height: auto;
  transition: all 0.2s;
}

.viken__docs-container_documents-list_box h2 {
  font-family: var(--font-family);
  color: #555;
  font-size: 22px;
  line-height: 28px;
  margin-top: 1rem;
}

.viken__docs-container_documents-list_box p {
  font-family: var(--font-family);
  color: #555;
  font-size: 14px;
  margin: 1rem 0rem;
}

.Viken__cover-wrapper {
  height: auto;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 500px;
}

.viken_modal-content a.modal__home-button {
  text-align: center;
  justify-content: center;
  display: flex;
  margin: 0 auto;
  padding: 0.4rem 2rem;
}

.viken_modal-content h2 {
  display: flex;
  font-family: var(--font-family);
  
  padding: 0.4rem 2rem;
  font-weight: 400;
  font-size: 28px;
  color: #333;
  flex: 2;
}

.viken_modal-content p {
  display: flex;
  font-family: var(--font-family);
  
  padding: 0.4rem 2rem;
  font-weight: 400;
  font-size: 18px;
  color: #333;
}

.viken_modal-content::after {
  display: flex;
  flex-direction: column;
  height: 80%;
  width: 80%;

  background: #fff;
  border-radius: 1rem;
  margin: 5% auto;
  padding: 2rem;
  box-shadow: 10px 20px 30px 10px rgba(0, 0, 0, 0.25);

  animation-name: fade-in-top;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;

  /* shorthand
		animation: fade-in-top 1s ease 0s 1 normal none;*/
}
@keyframes fade-in-top {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.close-button {
  color: #333;
  cursor: pointer;
  display: flex;
  font-size: 64px;
}

.viken__modal-wrapper {
  max-width: 33%;
  display: flex;
}

.viken__modal-feedback-container {
    text-align: center;
    width: 100%;
    background: var(--mdb-indigo);
    margin: auto;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 999;
}

.emoji_wrapper {
  display: flex;
  flex-direction: row;
}

.viken__modal-feedback-container p {
    display: flex;
    justify-content: center;
    font-size: 24px;
    font-weight: 500;
    text-transform: math-auto;
    color: var(--primary-light);
}

.feedback-smile {
  font-size: 54px;
  border: none;
  background: transparent;
  padding: 1rem 0.5rem;
  cursor: pointer;
}

.feedback-sad {
  font-size: 54px;
  border: none;
  background: transparent;
  padding: 1rem 0.5rem;
  cursor: pointer;
}

.button-click-animation {
  -webkit-animation: button-click-animation 0.9s both;
  animation: button-click-animation 0.9s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-1-16 14:27:37
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation jello-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes button-click-animation {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes button-click-animation {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

a.viken__passord-link {
  font-family: var(--font-family);
  transition: all 0.2s;
  text-align: center;
  outline: none;
  border: none;
  background-color: transparent;
  color: var(--color-500);
  font-size: 16px;
}

@media screen and (max-width: 1700px) {
  .toggle_button {
    top: 7%;
  }
}

@media screen and (max-width: 1220px) {
  .viken__modal-wrapper {
    max-width: 50%;
    display: flex;
    flex-direction: row;
  }
}

@media screen and (max-width: 1000px) {
  .toggle_button {
    top: 5%;
  }
}

@media screen and (max-width: 980px) {
  .viken_modal-content {
    width: 100%;
  }

  .close-button {
    left: 93%;
    top: 0%;
  }
}

@media screen and (max-width: 860px) {
  #modal-description {
    height: 240px;
  }
}

@media screen and (max-width: 700px) {
  .viken__modal-wrapper {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .viken__docs-container_documents-list_box img {
    height: 250px;
    margin: auto;
    width: 350px;
}

.Viken__cover-wrapper {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 350px;
}
}

@media screen and (max-width: 300px) {
  .viken_modal-content {
    height: 100%;
  }
}

/* END OF MODAL CSS */


.email_wrapper {
  display: flex;
  flex-direction: row;
}

.email-link {
  margin: 1rem 2rem;
  padding: 0.5rem 1rem;
  transition: all 0.2s;
  text-align: center;
  outline: none;
  border: none;
  max-width: 150px;

  font-family: var(--font-family);
  background-color: #006cad;
  color: #f2f2f2;
  font-size: 16px;
}

.email_wrapper button {
  padding: 0.5rem 1rem;
  margin: 0 1rem;

  background: linear-gradient(120deg, rgba(28, 94, 234) 0%, rgb(134, 118, 255) 90%);
  border: none;
  color: white;

  cursor: pointer;
}

.close-button-wrapper {
display: flex;
    width: 100%;
    height: auto;
    justify-content: end;
}

.slick-track {
      height: 720px !important;
}

.slick-list, .slick-slider {
  height: 100% !important;
}

.slick-next:before, .slick-prev:before {
    font-size: 50px !important;
    margin-left: -15px;
    color: var(--mdb-gray-600);
}