.viken__admin-header {
    display: flex;
    flex-direction: row;
    height: auto;

}
.admin_hero-glass {
    width: 50%;
}

.btn-toggle {
font-size: 38px;
}

.welcome_box {
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(16px);
-webkit-backdrop-filter: blur(16px);
}

@media screen and (max-width: 460px ) {
    .admin_hero-glass {
        width: 80% !important;
    }
}