.modals-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
}

.modals-list div {
    margin-bottom: 2rem;
}

.modals-list #modal-wrapper {
    width: 100% !important;
}