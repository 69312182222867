.viken__links-wrapper {
    display: flex;
    flex-direction: row;
    
}

#link-hover:hover {
filter: brightness(1.2);
transition: all 0.2s ease-in-out;
}

#link-hover {
    transition: all 0.2s ease-in-out;
}