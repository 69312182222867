//
// Card styles
//

.card {
  border: 0;

  .bg-image {
    border-top-left-radius: var(--#{$prefix}card-border-radius);
    border-top-right-radius: var(--#{$prefix}card-border-radius);
  }

  &[class*='bg-'] {
    .card-header {
      --#{$prefix}card-header-border-bottom-color: #{$card-border-color};

      border-bottom-color: var(--#{$prefix}card-header-border-bottom-color);
    }
    .card-footer {
      --#{$prefix}card-footer-border-top-color: #{$card-border-color};

      border-top-color: var(--#{$prefix}card-footer-border-top-color);
    }
  }
}

.card-header {
  --#{$prefix}card-header-border-width: #{$card-header-border-width};
  --#{$prefix}card-header-border-color: #{$card-header-border-color};

  border-bottom: var(--#{$prefix}card-header-border-width) solid var(--#{$prefix}card-header-border-color);
}

.card-body {
  &[class*='bg-'] {
    border-bottom-left-radius: var(--#{$prefix}card-border-radius);
    border-bottom-right-radius: var(--#{$prefix}card-border-radius);
  }
}

.card-footer {
  --#{$prefix}card-footer-border-color: #{$card-footer-border-color};
  --#{$prefix}card-footer-border-width: #{$card-footer-border-width};

  border-top: var(--#{$prefix}card-footer-border-width) solid var(--#{$prefix}card-footer-border-color);
}

.card-img-left {
  border-top-left-radius: var(--#{$prefix}card-border-radius);
  border-bottom-left-radius: var(--#{$prefix}card-border-radius);
}
