.create_changelog-wrapper {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: var(--mdb-bg-light);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.theme-toggle {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.dark-mode .create_changelog-wrapper {
  background: var(--mdb-bg-dark);
}

h4 {
  text-align: center;
}

.changelog_input-form {
  margin-bottom: 20px;
}

.error-message {
  color: var(--mdb-danger);
  text-align: center;
}

.success-message {
  color: var(--mdb-success);
  text-align: center;
}
