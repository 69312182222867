@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

.gradient__bg {
    
    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

}

.gradient__text {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clop: text;
    -webkit-text-fill-color: transparent
}


.section__padding {
    padding: 4rem 6rem;
}

.gradient-bar {
    background: var(--gradient-bar);
    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
    height: 3px;
    margin-bottom: 0.25rem;
    width: 68px;
    justify-content: center;
    align-items: end;
    display: flex;
}

.icon_margin {
  margin: auto 0.5rem !important;
}


.glass-card {
background: rgba(137, 137, 137, 0.05) !important;
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37); 
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(16px);
-webkit-backdrop-filter: blur(16px);
}







.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}


@media screen and (max-width: 700px) {
    .section__padding {
        padding: 3rem 1rem !important;
    }
}


@media screen and (max-width: 550px) {
    .section__padding {
        padding: 2rem 0.5rem !important;
    }
}





@media screen and (max-width: 1080px) {
  
  .current-location { 
    left: 0 !important;
  }

}