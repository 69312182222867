.predefined-button {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    border: none;
    color: white;
    margin: 0.5rem;
    cursor: pointer;
}

.predefined-button.meeting {
    background: var(--bc-success-color-400);
}

.predefined-button.waitingtime {
    background: var(--bc-danger-color-400);
}

.predefined-button.restart {
    background: var(--bc-dark-background-color-200)
}

.predefined-inputs p {
    font-size: 18px;
    margin: 1rem 0rem;
}

.square {
    height: 100px;
    width: 100%;
    color: #fff;
    padding-top: 38px;
    border-radius: 0.5rem;
}