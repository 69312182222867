.admin_bg {
    display: flex;
    flex: 1;
    height: 800px;
}

.viken_logo {
    margin: 1rem 0rem;
}


.viken__admin-container h2 {
    font-family: var(--font-family);
    color: #E8E8E9;
    font-weight: 800;
    font-size: 28px;
    line-height: 32px;
    flex: 10;
}



.viken__admin-stats_1 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: center;
}

.viken__admin-stats-wrapper {
    display: flex;
    flex-direction: column;
}
