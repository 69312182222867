.viken__login-wrapper {
    display: flex;
    height: 100vh;
    background-image: url(https://images.unsplash.com/photo-1620641788421-7a1c342ea42e?q=80&w=3474&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.img50 {
    width: 50%;
    display: flex;
    flex: 1;
}

.viken__login-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.75);
    color: var(--bc-dark-background-400);
    font-family: var(--font-family);
}

.viken__login-image {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 4rem;
    flex: 1;
    background: linear-gradient(45deg, rgba(255,100,100, 0.8) 30%, rgba(200,50,40, 0.7) 90% );
}

.viken__login-image h2 {
    font-size: 44px;
    font-weight: 800;
}

.viken__login-image p {
    font-size: 18px;
    padding: 2rem;
}

.viken__login-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1.5;
    text-align: center;
}

.viken__login-form_text {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem;
}

.viken__login-form_text h3 {
    display: flex;
}

.viken__login-form_text p {
    display: flex;
    width: 60%;
    padding: 1rem 0rem;
}

.viken__login-form_text form {
    display: flex;
    flex-direction: column;
}

.login-form_input {
    padding: 1rem 0rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.login-form_input input {
    padding: 0.8rem 1rem;
    width: 340px;
    border-radius: 2rem;
    outline: none;
    border: none;
}

.login-form_input h5 {
    padding: 0.8rem 0.5rem;
}

.login-form_submit {
    padding: 1rem 0rem;
}

.login-form_submit button {
    display: flex;
    margin: 0 auto;
    border: none;
    outline: transparent;
    cursor: pointer;
    transition: all 0.2s ease-in;

    padding: 0.8rem 2rem;
    font-family: var(--font-family);
    font-size: 16px;
    border-radius: 2rem;

    background: linear-gradient(45deg, rgba(76, 118, 255, 1) 25%, rgba(40,60,255, 1));
    color: white;
}

.login-form_submit button:hover {
    filter: drop-shadow(2px 2px 2px var(--bc-dark-background-400)) !important;
}


#faculty {
    border: 1px solid;
    font-family: var(--font-family);
    font-size: 16px;
    outline: none;
    padding: 0.2rem 0.5rem;
    margin: 1rem 0rem;
    border-radius: 1rem;
    display: flex;
    text-align: center;
}

.facultySelect {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-width: 1024px) {
    
}