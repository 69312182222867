.viken__userlist-wrapper {
    height: auto;
    display: flex;
    flex-direction: column;
    width: 100%;

    font-family: var(--font-family);
}

.profile-picture {
    border-radius: 10rem !important;
    height: 60px;
    width: 60px !important;
}

.viken__userlist-header h3 {
    display: flex;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(135deg,#47a0cc,#00abff);
    -webkit-background-clip: text;
    background-clip: text;
    font-size: 34px;
    font-weight: 800;
    padding: 2rem;
    justify-content: center;
}


.confirm_delete_modal {
    height: 200px;
    inset: 40px;
    outline: none;
    overflow: auto;
    padding: 2rem;
    position: absolute;
    width: 500px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: rgba(0, 0, 0, 0.43) !important;
    border-radius: 16px !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
    backdrop-filter: blur(10.2px) !important;
    -webkit-backdrop-filter: blur(10.2px) !important;
    border: 1px solid rgba(0, 0, 0, 0.17) !important;
}

.confirm_delete_modal p {
    justify-content: flex-start;
    display: flex;
    font-size: 18px;
    font-family: var(--font-family);
    color: #f3f3f3;
}


.confirm_button-wrapper {
    display: flex;
    flex-direction: row;
}

.button_yes:hover, .button_no:hover {
    border-radius: 1rem;
    box-shadow: 1px 4px 10px 3px rgba(0,0,0,.6);
    transition: all 0.4s ease;
}

.user_icon {
    margin: 0rem 1rem;
    border-radius: 10rem;
    height: 60px;
    width: 60px;
    background-color: white;
}