.start_button {
    font-family: var(--font-family);
    margin: 0.5rem 2rem;
    top: 70px;
}

.start_button:hover {
    transition: all 0.4s ease;
    box-shadow: 1px 2px 2px 2px rgba(0, 0, 0, 0.4);
}


@media screen and (max-width: 800px) {
    .start_button {
        top: 60px;
}
}