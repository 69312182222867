@import './mdb.pro.scss';

$my-theme-primary: #3b71ca;
$my-theme-secondary: #ebedef;

$my-light-theme: mdb-light-theme($my-theme-primary, $my-theme-secondary);

.light {
    @include mdb-theme($my-light-theme);
}



@import './mdb.pro.scss';

// DARK SKIN
$my-theme-primary: #9a43f6;
$my-theme-secondary: #e4e3f7;
$my-dark-theme: mdb-dark-theme($my-theme-primary, $my-theme-secondary);

.dark {
    @include mdb-theme($my-dark-theme);
}






@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');


:root {
    /***** Main color selection *****/
    --color-500: #007CBA;
    --color-400: #1989C0;
    --color-300: #47A0CC;
    --color-200: #7EBCDB;
    --color-100: #B1D6E9;
    /***** End Main color selection *****/

    --bc-background-color: #fff;
    --bc-background-color-2: #f3f3f3;
    --bc-background-color-3: #dddddd;
    --bc-dark-background-color-500: #111111;
    --bc-dark-background-color-400: #373737;
    --bc-dark-background-color-300: #454545;
    --bc-dark-background-color-200: #6a6a6a;
    --bc-dark-background-color-100: #afafaf;
    --bc-success-color-500: #2bc02b;
    --bc-success-color-400: #48d648;
    --bc-success-color-300: #72df72;
    --bc-success-color-200: #9be99b;
    --bc-success-color-100: #effbef;
    --bc-danger-color-500: #c0392b;
    --bc-danger-color-400: #d65548;
    --bc-danger-color-300: #df7c72;
    --bc-danger-color-200: #e9a39b;
    --bc-danger-color-100: #fbf0ef;
    --bc-warning-color-500: #e4d500;
    --bc-warning-color-400: #fff018;
    --bc-warning-color-300: #fff34b;
    --bc-warning-color-200: #fff77e;
    --bc-warning-color-100: #fffde4;

    /* BUTTON COLORS */
    --primary: #155bd5;
    --primary-dark: #1c3ab6;
    --primary-light: #e2f1ff;
    --accent: #00d4d7;
    --accent-dark: #00bac1;
    --accent-light: #dff9f8;
    --success: #13d527;
    --success-dark: #00ae11;
    --success-light: #eafbe7;
    --secondary: #8f15d5;
    --secondary-dark: #6013c7;
    --secondary-light: #f4e5fa;
    --info: #15b2d5;
    --info-dark: #0f8ca8;
    --info-light: #e0f5fa;
    --caution: #dbbb25;
    --caution-dark: #d58f15;
    --caution-light: #fbf9e4;
    --error: #e6185e;
    --error-dark: #bf1257;
    --error-light: #fce4eb;
    --black: #1d1d1d;
    --dark-1: #2d2d2d;
    --dark-2: #4d4d4d;
    --dark-3: #6d6d6d;
    --gray-1: #8d8d8d;
    --gray-2: #adadad;
    --gray-3: #cdcdcd;
    --gray-4: #e0e0e0;
    --light-1: #efefef;
    --light-2: #f5f5f5;
    --light-3: #fafafa;
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.dark {
    background-color: #1c1c1c;
}

body {
    margin: 0;
    padding: 0;
    min-height: 100vh;

    font-family: Roboto, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fixed-position {
    position: fixed;
}

.overflow-hidden {
    overflow: hidden !important;
}

