.viken__navbar-latest_news p {
    color: white;
    font-family: var(--font-family);
    padding: 0rem 0.4rem;
    text-transform:uppercase;
    margin-bottom: 0 !important;
    font-size: calc(1rem + .6vw);

    	-webkit-animation: pulsate-bck 1.5s ease-in-out infinite both;
	        animation: pulsate-bck 1.5s ease-in-out infinite both;
}

@-webkit-keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.99);
            transform: scale(0.99);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.99);
            transform: scale(0.99);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}


.navbar-wrapper {
    display: flex;
    width: 100%;
}


.viken__navbar-latest_news {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    background: var(--primary);
    z-index: 999;
    box-shadow: 0px 2px 8px 2px rgb(0 0 0 / 50%);
    width: 100%;
    position: relative;
    height: 50px;
}

.viken__navbar-latest_news h3 {
    color: var(--primary-light);
    margin-bottom: 0 !important;
}



.viken__navbar {
    display: none;
    justify-content: space-between;
    align-items: center;
    position: relative;

    box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 25%);
    background: #fff;
    padding: 2rem 6rem;
}

.viken__navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}


.viken__navbar-links_logo {
    margin-right: 2rem;
    display: flex;
    flex: 2;
}


.viken__navbar-links_logo img {
    width: 180px;
    height: 50px;
}

.viken__navbar-links_container {
     display: flex;
     flex-direction: row;
}

.viken__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.viken__navbar-links_container p,
.viken__navbar-sign p,
.viken__navbar-menu_container p {
    font-size: 1.375rem;
    font-weight: 400;
    color: #003b5c;
    font-family: var(--font-family);
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;

    cursor: pointer;
}

.viken__navbar-sign button,
.viken__navbar-menu_container button {
    padding: 0.5rem 2rem;
    color: #fff;
    background: #ff4820;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border-radius: 5px;
    border: 0;
    outline: none;
    cursor: pointer;
}


.viken__navbar-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.viken__navbar-menu svg {
    cursor: pointer;
}

.viken__navbar-menu_container {
display: flex;
justify-content: flex-end;
align-items: flex-end;
flex-direction: column;

text-align: end;
background: #fff;
padding: 2rem;
position: absolute;
top: 40px;
right: 0px;

margin-top: 1rem;
min-width: 210px;
border-radius: 5px;
box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);

}

.viken__navbar-menu_container-links p {
    margin: 1rem 0;
}

.viken__navbar-menu_container-links-sign {
    display: none;
}

@media screen and (max-width: 1080px) {
    .viken__navbar-links_container {
        display: none;
    }
    .viken__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .viken__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .viken__navbar {
        padding: 2rem;
    }
    .viken__navbar-sign {
        display: none;
    }
    .viken__navbar-menu_container {
        top: 20px
    }
    .viken__navbar-menu_container-links-sign {
        display: none;
    }
    .viken__navbar-latest_news p {
        font-size: 0.8rem;
    }
}