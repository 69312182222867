.viken__docs-search_icon {
  color: white;
  font-size: 22px;
}

#docs {
  min-height: 92vh;
}

.viken__docs-container_search {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.viken__docs-container_search h4 {
  font-family: var(--font-family);
  font-weight: 400px;
  font-size: 20px;
  color: #555;
}

.viken__docs-container_search form {
  border-radius: 10rem;
  border: 2px solid var(--color-footer);
  display: flex;
  align-items: center;
  width: 30%;
  background-color: none;
  justify-content: center;
}

.viken__docs-container_search input {
  border: none;
  outline: none;
  display: flex;
  padding: 0.7rem 1rem;
  background-color: transparent;
  width: 100%;
  font-family: var(--font-family);
  color: #333;
  font-size: 18px;
  height: 100%;
}

.hg-theme-default {
  max-width: 850px;
  color: var(--mdb-gray-600);
  top: 20%;
  position: absolute;
  z-index: 999;
  padding: 1rem;
}

.viken__docs-container_search button {
  border: none;
  outline: none;
  display: flex;
  padding: 0.8rem 1rem;
  background-color: var(--color-200);
  cursor: pointer;
  font-size: 18px;
  color: #333;
  border-radius: 10rem;
  margin: 0.5rem;
  transition: all 0.3s ease-in-out;
}

.viken__docs-container_search button:hover {
  background-color: var(--color-300);
  box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 25%);
}




@media screen and (max-width: 1440px) {
  .viken__docs-container_documents-list_box h2 {
    font-size: 22px !important;
    padding: 0.5rem 0.6rem;
    margin: 0.8rem 0.2rem;
  }
}

@media screen and (max-width: 800px) {
  .viken__docs-container_search input {
    font-size: 14px !important;
    padding: 0.7rem 0.5rem !important;
    width: 300px !important;
}

.viken__docs-container_search form {
  border: 1px solid var(--color-footer) !important;
  width: 100% !important;
}
}