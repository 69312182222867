.faculty_administration-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    font-family: var(--font-family);
}

.faculty_administration-wrapper form {
    display: flex;
    flex-direction: column;
    height: auto;
}

.form_input {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 1rem;
}



.delete_faculty-wrapper h2 {
    color: var(--primary);
}

.faculty_delete-wrapper {
    font-family: var(--font-family);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    height: auto;
    left: 25%;
    margin: auto;
    padding: 2rem;
    position: fixed;
    top: 25%;
    width: 50%;
    background: var(--light-1);
}

.faculty_delete-wrapper p {
    margin: 4rem 0rem 1rem 0rem;
}

.faculty_delete-wrapper ul {
    margin-top: 2.5rem;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.faculty_delete-wrapper li {
    list-style: none;
    margin: auto;
}