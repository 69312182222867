.viken__profile-wrapper {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;;

    font-family: var(--font-family);
}

.profile_header {
    --mdb-gutter-x: 0rem !important;
}

.viken__profile-container {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    font-family: var(--font-family);
}

.viken__profile-container h2 {
    text-align: center;
    padding: 1rem 0rem;
    font-weight: 800;
    font-size: 44px;

    /* text color gradient */
    background: linear-gradient(135deg, #258eff 0%, #5613ff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.viken__profile-container_form {
    height: 100%;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 2rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 40px linear-gradient(90deg, rgba(255, 255, 255, 255.1), rgba(54,54,54,0.6)) inset !important;
    box-shadow: 0 0 0 40px linear-gradient(90deg, rgba(255, 255, 255, 255.1), rgba(54,54,54,0.6)) inset !important;
}

.role-display {
    margin: 1.5rem 0rem;
}

.biografi {
    overflow-wrap: break-word;
    word-break: break-all;
}

#profileImg {
    width: 168px;
    height: 168px;
    margin-top: -289px;
    border-radius: 10rem;
    margin-left: 82px;
    z-index: 99999;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    padding: 2rem;
}

#MDBFileUpload label {
    text-align: center;
    justify-content: center;
    display: flex;
    position: relative;
    top: 20px;
}


.search-results {
    width: 25% !important;
    cursor: pointer;
    z-index: 999;
}

.search-result-item {
    transition: all 0.2s ease-in-out;
}

.search-result-item:hover {
    backdrop-filter: brightness(0.2);
    transition: all 0.2s ease-in-out;
}

#admin-modal-wrapper #modal-wrapper {
    max-width: 25% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}