/* SCROLL TO DOCS BUTTON */

.QR_code {
    background: #fff;
    padding: 0.5rem;
    border-radius: 1rem;
}

.scroll-to-docs svg {
  border: none;
  background: transparent;
  font-size: 68px;
  color: #fff;
  position: static;
  bottom: 0%;
  cursor: pointer;
  border-radius: 10rem;
  transition: all 0.3s;
  z-index: 9999;
}
.scroll-to-docs {
  background: transparent;
  position: static;
  bottom: 0%;
  border: none;
  outline: none;
  margin: auto;
  height: 100%;
  width: 100%;
  cursor: pointer;


  color: #fff;
  font-family: var(--font-family);
  font-size: 36px;
  font-weight: 700;
  text-transform:uppercase;
}


.button_start-wrapper {
    text-align: center;
    padding: 1rem;
}

.button_start-text p {
    padding: 1rem;
    font-family: var(--font-family);
    font-size: 1.6vh;
}


.button_start {
    width: 200px;
    height: 200px;
    border-radius: 10rem;
    background: #333;
    margin: auto;
            -webkit-animation: pulsate-bck 1.5s ease-in-out infinite both;
            animation: pulsate-bck 1.5s ease-in-out infinite both;
}
@-webkit-keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}



/* SCROLL TO DOCS BUTTON */


.viken__header-wrapper.slide-out-blurred-top {
	-webkit-animation: slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

@-webkit-keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-2000px);
            transform: translateY(-2000px);
  }
}
@keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-2000px);
            transform: translateY(-2000px);
  }
}



/* END OF ANIMATION */



.viken__header-wrapper {
    background-color: #fff;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 90px;
    width: 100%;
    z-index: 999;
    display: flex;
}

.viken__header-wrapper video {
  min-width: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
}

.viken__header-content {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 999;
    display: flex;
    flex-direction: column;
    
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.viken__header {
    display: flex;
    flex-direction: row;
}

.viken__header-container {
    display: flex;
    flex-direction: column;
    flex: 2 1;
    align-items: center;
    text-align: center;
}

.viken__header-container p {
    color: var(--dark-1);
    font-family: var(--font-family);
    width: 100%;
}
.viken__header-container h1 {
    color: var(--color-500);
    font-family: var(--font-family);
    font-weight: 800;
}


.viken__header-stats_icon {
    width: 100%;
    color: var(--color-500);
    font-size: 44px;
    margin: auto;
    padding: 0.5rem 0rem;
}

.viken__header-stats {
    display: flex;
    flex-direction: column;
    padding: 2rem 6rem;
    margin-top: 10%;
}

.viken__header-stats_icons-text-list {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.viken__header-stats_icons-text {
    width: 100%;
    text-align: center;
    padding: 2rem 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--font-family);
    font-size: 16px;
    color: var(--dark-1);
}

.viken__header-stats_icons-text h4 {
    font-family: var(--font-family);
    font-size: 18px;
    padding: 1rem 0rem;
}





@media screen and (max-width: 1520px) {
.viken__header-stats_icons-text h4 {
    font-family: var(--font-family);
    font-size: 16px !important;
    padding: 1rem 0;
  }

.viken__header-stats_icon {
    color: var(--color-500);
    font-size: 34px;
    margin: auto;
    padding: 0.5rem 0;
    width: 100%;
  }
}



@media screen and (max-width: 1080px) {
    .viken__header {
        flex-direction: column;
        margin-top: 2rem !important;
    }
}

@media screen and (max-width: 992px) {
  .viken__header-container .display-1 {
    font-size: calc(1.625rem + 2.5vw);
  }
    .viken__header-container p.fs-4 {
      font-size: calc(0.875rem + .3vw) !important;
    }

    .viken__header-stats_icons-text-list {
      flex-direction: row !important;
    }


    .button_start {
      width: 100px !important;
      height: 100px !important;
    }

    .button_start.mt-5 {
      width: 100px !important;
      height: 100px !important;
      margin-top: 0rem !important;
    }

    .viken__header-wrapper {
      top: 0px !important
    }
    .scroll-to-docs {
      font-size: 24px !important;
    }
}

@media screen and (max-height: 1110px) {
  .viken__header-container .display-1 {
    font-size: calc(1.625rem + 2.5vw);
  }
    .viken__header-container p.fs-4 {
      font-size: calc(0.875rem + .3vw) !important;
    }


    .button_start {
      width: 100px !important;
      height: 100px !important;
    }

    .button_start-wrapper.mt-5 {
      margin-top: 0rem !important;
    }

    .viken__header-wrapper {
      top: 0px !important
    }
    .scroll-to-docs {
      font-size: 24px !important;
    }
}

@media screen and (max-width: 950px) {
    .viken__header-stats_icons-text h4 {
        font-family: var(--font-family);
        font-size: 16px;
    }
    .viken__header-stats_icon {
        width: 33.33%;
        color: var(--color-500);
        font-size: 34px;
        text-align: center;
    }
}

@media screen and (max-width: 850px) {
    .viken__header-stats_icons-text h4 {
    font-family: var(--font-family);
    font-size: 14px !important;
    padding: 0!important;
    }

    .viken__header-stats_icon {
        width: 100%;
        color: var(--color-500);
        font-size: 26px;
        text-align: center;
    }

.viken__header-stats {
    flex-direction: column;
    }

.viken__header-stats_icons-text-list {
    height: auto;
    }
    
    .viken__header-stats_icons-text {
      width: 50%;
  }

  .viken__header-wrapper {
    overflow: scroll;
  }

  .viken__header-stats_icons-text {
    width: 50% !important;
}

.QR_code {
  height: 64px !important;
  width: 64px !important;
}
}

@media screen and (max-width: 700px) {
.viken__header-container p {
    font-size: 16px !important;
    line-height: 18px;
  }

  .viken__header-container p {
    font-size: 12px;
    line-height: 14px;
}

  .viken__header-container h1 {
    font-size: 18px !important;
  }

  .QR_code {
    display: none !important;
}

.viken__header-stats_icons-text-list {
    height: auto;
    display: flex;
    flex-direction: row !important;
}

.viken__header-stats_icons-text {
  background: transparent !important;
}

.viken__header-container p {
    line-height: 22px !important;
    width: 100% !important;
}

.viken__header-wrapper {
    height: 100vh !important;
}

.viken__header {
    display: flex;
    flex-direction: row;
    margin-top: 0.5rem;
  }
  
  .intro_text {
    display: none;
  }

    .viken__header-stats_icon {
        width: 100%;
        color: var(--color-500);
        font-size: 22px;
        text-align: center;
    }

  .scroll-to-docs {
    font-size: 14px !important;
    }

    .button_start {
    height: 60px !important;
    width: 60px !important;
    }

}
@media screen and (max-width: 485px) {
    .viken__header-container h1 {
        font-size: 18px;
    }

    .viken__header-container p:first-child {
        font-size: 14px;
    }

    .viken__header-container p {
        font-size: 14px;
        line-height: 22px;
    }

    .viken__header-stats_icons-text {
  padding: 1rem 0;
    }
    .joke_text {
      display: none;
    }
}